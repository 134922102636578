document.querySelectorAll('.categoriesIndex > li').forEach((li) => {
  // Within each top-level <li>, select all links under .level-1 > li
  li.querySelectorAll('.level-1 > li > a').forEach((link) => {
    // jQuery's .width() typically gives the content width
    // For a close equivalent, you can use getBoundingClientRect().width
    const leftValue = link.getBoundingClientRect().width + 20;

    // Find the nested .level-2 element and set its left position
    const level2 = link.parentElement?.querySelector('.level-2');
    if (level2 instanceof HTMLElement) {
      level2.style.left = `${leftValue}px`;
    }
  });
});

// Select the input element(s)
const inputSearch = document.querySelector('.contentIndex .search input[type="search"]');
if (inputSearch instanceof HTMLElement) {
// If you have multiple inputs, replace the above line with:
// const inputsSearch = document.querySelectorAll('.contentIndex .search input[type="search"]');

  // Add a focus event listener
  inputSearch.addEventListener('focus', () => {
    inputSearch.style.transition = 'width 0.3s'; // Transition similar to jQuery.animate()
    inputSearch.style.width = '440px';
  });

  // Add a blur event listener
  inputSearch.addEventListener('blur', () => {
    inputSearch.style.transition = 'width 0.3s';
    inputSearch.style.width = '220px';
  });
}
